import React from "react"
import PropTypes from "prop-types"
import { Flex, Box, Text, Image } from "rebass"

const MobileFrame = ({
  imageSrc,
  width,
  height,
  scale,
  scrollable,
  style: propStyles,
}) => {
  const deviceWidth = width * scale
  const deviceHeight = height * scale
  const screenWidth = deviceWidth - 46 * scale
  const screenHeight = deviceHeight - 202 * scale
  const toScroll = scrollable ? "scroll" : "hidden"
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      style={{
        width: deviceWidth,
        height: deviceHeight,
        borderRadius: 68 * scale,
        border: "solid 1px #2d2f2e33",
        position: "relative",
        ...propStyles,
      }}
    >
      {scrollable && (
        <Box
          style={{
            position: "absolute",
            top: "-1rem",
            right: "-1rem",
            textAlign: "center",
            borderRadius: "50%",
            border: "dashed 1px #2d2f2e33",
            background: "#fcfbf7aa",
            padding: "1.25rem 1rem",
            height: "5rem",
            width: "5rem",
          }}
        >
          <span style={{ fontSize: "0.75rem" }}>
            Scroll
            <br />
            Me
          </span>
        </Box>
      )}
      <Box
        style={{
          width: screenWidth,
          height: screenHeight,
          borderRadius: 1,
          border: "solid 1px #2d2f2e33",
          boxSizing: "content-box",
          display: "block",
          overflowY: toScroll,
        }}
      >
        {imageSrc ? (
          <Image src={imageSrc} style={{ margin: 0, width: "100%" }} />
        ) : (
          <Text>Preview unavailable</Text>
        )}
      </Box>
    </Flex>
  )
}

MobileFrame.propTypes = {
  imageSrc: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  scale: PropTypes.number,
  scrollable: PropTypes.bool,
  style: PropTypes.object,
}

MobileFrame.defaultProps = {
  /* Google Pixel 2 */
  width: 457,
  height: 933,
  scale: 1,
  scrollable: false,
}

export default MobileFrame
